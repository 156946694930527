.alert{
    position: fixed;
    top: -20%;
    left: 50%;
    transform: translate(-50%, 25%);

    border-radius: 30px;
    border: 1px solid $light-grey;

    padding: 25px 10px;

    background-color: white;

    transition: .3s ease-in-out;
    
    width: 100%;

    box-sizing: border-box;

    font-size: 14px;

    @media screen and (max-width: $mobileMax) {
    }
    @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
    }
    @media screen and (min-width: $desktopMin) {
        width: 30%;
    }

    &-wrapper{
        display: none;
    }

    &.active{
        top: 0%;
    }

    p{
        text-align: center;
    }
}