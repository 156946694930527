.State_selector_orders{
font-weight: 800;
&.Invoiced{
    color: $invoiced-dark;
}

&.Refund{
    color: #000;
}
&.Pending{
    color: $pending-dark;
}
&.pending{
    color: $pending-dark;
}
&.Approved{
    color: $approved-dark;
}
&.Declined{
    color: $declined-dark;
}
&.ReadyToPack{
    color: $readyToPack-dark;
}
&.ReadyToDispatch{
    color: $readyToDispatch-dark;
}
&.Dispatch{
    color: $dispatch-dark;
}



.options_Invoiced{
    color: $invoiced-dark;
font-weight: 800;
    
}

.options_Refund{
    color: $declined-dark;
    font-weight: 800;

}
.options_Pending{
    color: $pending-dark;
font-weight: 800;
    
}
.options_Approved{
    color: $approved-dark;
font-weight: 800;
}
.options_Declined{
    color: $declined-dark;
font-weight: 800;
}
.options_ReadyToPack{
    color: $readyToPack-dark;
font-weight: 800;
}
.options_ReadyToDispatch{
    color: $readyToDispatch-dark;
font-weight: 800;
}
.options_Dispatch{
    color: $dispatch-dark;
font-weight: 800;
}
.options_Refund{
    color: $refund-dark;
font-weight: 800;
}
}