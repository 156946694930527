.fileuploadmodal {
    display: grid;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100vh;

   // background-color: rgba(0, 140, 150, 0.678);
    z-index: 9999; 
  overflow: auto;

    .fileuploadmodalmodal-content {
      display: grid;
    margin: auto auto;
    position: relative;
        align-items: center;
   //  background-color: rgb(139, 20, 20);
       background-color: #f1f1f1;
      padding: 20px;
      border-radius: 5px;
      width: 80%;
      height: 90%;
      max-height: 100VH;  
      z-index: 9999; 
      grid-template-columns: 1fr;
      grid-template-rows: minmax(1fr,150px) minmax(3fr,300px)  minmax(1fr,150px);

      .fileuploadmodalmodal-header {
   //  background-color: rgb(5, 50, 148);
     display: grid;
        grid-template-columns: 1fr 1fr;
      z-index: 9999; 
      color: $Primary;
        justify-content: center;
        h2{
          margin: 0;
          padding: 0;
          font-size: 20px;
        }
        .close-button {
          border: none;
          background-color: transparent;
          font-size: 20px;
          cursor: pointer;
        }
      }
  
      .fileuploadmodalmodal-body {
      //  background-color: #9daf4c;
        display: grid;
        width: 95%;
      z-index: 9999; 
      margin: 0 auto;
height:100%;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(5fr,300px) minmax(1fr,150px);
        .dropzone {
  max-height: 100vh;

            box-shadow: $shadow;
            color: $Primary;
            margin: 0 auto;
        border-radius: 5px;
     //   background-color: #4c4eaf;
        width: 100%;
          height: 90%;
          border: 2px solid black;
          display: grid;
          justify-content: center;
      z-index:1000; 
      align-items: center;
          margin-bottom: 20px;
  
          p {
            font-size: 16px;
          }
        }
  
        .selected-files {
            height: 150px;
           max-height: 150px;
          display: grid;
      // background-color: #5b0072;
      overflow-y: scroll;

      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      
          .file {
          //  background-color: #4caf50;
            color: $Primary;
            display: grid;
            padding: 10px;
            max-width: 150px;
            max-height: 150px;
            border-radius: 5px;
            margin-right: 10px;
            margin-bottom: 10px;
            .image-preview{
                width: 100%;
                height:100%;
                object-fit: contain;
            }
            .preview-image {
                display: grid;
                width: 100%;
                height:100%;
                max-height: 100px;
                object-fit: contain;
              }
            p {
              margin-right: 10px;
            }
  
            button {
              margin: 0 auto;
border-radius: 0;
              border: 1px red solid;
              background-color: transparent;
              color: red;
              cursor: pointer;
            }
          }
        }
      }
  
      .fileuploadmodalmodal-footer {
        display: grid;
        margin-top: 20px;
//        background-color: #ad3100;
  grid-template-columns: 1fr 1fr;
        button {
          margin-left: 10px;
          padding: 10px;
          border-radius: 5px;
          border: none;
          cursor: pointer;
  
          &:first-child {
            background-color: #4caf50;
            color: white;
          }
  
          &:last-child {
            background-color: #f44336;
            color: white;
          }
        }
      }

.edit-modal{
  display: grid;
  place-content: center;
  margin: 0 auto;
  width: 80vw;

  .edit-modal-content{
    position: absolute;
    top: 0;
    left: 10;
    z-index: 991000;

    width: 80vw;
    height: 50vh;
   max-width: 1000px;
    max-height: 700px;
    margin-top: 350px;
margin: 350px auto 0 auto;
    .responsive-image-editor {
  

    z-index: 991000;
    margin-top: 125px;
    /*  display: grid;
    place-content: center;*/
    }
  }
}
    
    }
  }
