.loading-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
  }
  
  .loading-animation div {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $Primary-Theme-Green;
    margin-right: 5px;
    outline: 2px solid white;
    animation: loading 1s ease-in-out infinite;
  }
  
  .loading-animation div:nth-child(2) {
    animation-delay: 0.1s;
  }
  
  .loading-animation div:nth-child(3) {
    animation-delay: 0.2s;
  }
  
  .loading-animation div:nth-child(4) {
    animation-delay: 0.3s;
  }
  
  @keyframes loading {
    0% {
      transform: translate(0, 0);
      opacity: 20%;
    }
  
    50% {
      transform: translate(0, 0);
      opacity: 80%;
    }
  
    75% {
      transform: translate(0, 20px);
      opacity: 100%;
    }
  
    100% {
      transform: translate(0, 0);
      opacity: 20%;
    }
  }
  