//Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

//Media Query Sizes
$mobileMin: 0px;
$mobileMax: 480px;
$tabletMin: 481px;
// $tabletMax: 1023px;
// $desktopMin: 1024px;
$tabletMax: 1365px;
$desktopMin: 1366px;
$desktopSmall: 720px;
$desktopLarge: 1920px;


//THEME PROPERTIES
$unfocused-green: #6d7a6d;
$Background_Light: hsl(0, 0%, 100%);
$bg-red: hsl(0, 60%, 98%);
$Secondary: #4952a3;
$Primary: #0a6887;
$Primary-Green: #25D442;

$Primary-Theme-Green: #0a6887;
$Primary-Royal-Blue: #259ED4;
$Primary-Blue: #14185B;
$Primary-Blue-Background: #EEEFFF;

//$error: rgb(216, 0, 0);
$danger: rgb(248, 81, 73);
$lightest-grey:rgb(245, 245, 245);
$lighter-grey: #f1f1f1;
$light-grey: #e5e5e5;
$unfocused-grey: #9A9A9A;
$main-grey: #2b2b2b;
$shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.295);
$shadow_2: 0px 3px 8px 0px rgba(0, 0, 0, 0.568);
$button-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.418);
$button-yoffset-shadow: 0px -15px 25px 0px rgba(0, 0, 0, 0.158);

$primary-color: #007bff;
$secondary-color: #6c757d;
$light-gray: #f8f9fa;
$gray: #e9ecef;
$dark-gray: #343a40;

$gap: 1rem;
$padding: 1rem;



// Define colors for each state
$pending-light: #dddddd3b;
$pending-dark: #3d3d3d;

$approved-light: #c4e4f43b;
$approved-dark: #0B5394;

$readyToPack-light: #FCE8B23b;
$readyToPack-dark: #B45F06;

$dispatch-light: #D9EAD33b;
$dispatch-dark: #3B7E31;

$readyToDispatch-light: #FBD5B53b;
$readyToDispatch-dark: #7F6000;

$declined-light: #ffe7e73b;
$declined-dark: #b40000;

$invoiced-light: #3636363b;
$invoiced-dark: #3a3a3a;

$refund-light: #F5F5F53b;
$refund-dark: #2D3E50;

$refund-light: #d3c5003b;
$refund-dark: #4e4900;

