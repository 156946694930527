.Routes_Table{
    //background-color: aqua;
    width: 98%;
    margin: o auto;

    display: grid;


    .Routes_Table_row{
        width: 80%;
        display: grid;
        grid-template-columns: repeat(auto-fit, 250px); /* set a fixed width of 200px for each column */
        border-radius: 5px;
   font-size: 14px;
      place-content: center;
        font-weight: lighter;
        grid-template-areas: 
        "Route_id Route_name Route_Action Route_Sales_rep ";
        padding: 0;
        max-height: 40px;
        margin: 0 auto;
        .Route_id{
            display: grid;
            grid-area: Route_id;
        }
        .Route_name{
            display: grid;
            grid-area: Route_name;
        }
        .Route_Action{
            display: grid;
            grid-area: Route_Action;
        }
        .Route_View{
            display: grid;
            grid-area: Route_View;
        }
        .Route_Sales_rep{
            display: grid;
            grid-area: Route_Sales_rep;
        }

        border: .5px solid #0a688727;
        &.Title{
            background-color: #0a6887;
            border-radius: 5px;
            color: white;
}
z-index: 10;
transition: 1s ease-in;
&:hover{
    &.Title{
        background-color: #0a6887;
        border-radius: 5px;
        color: white;
        scale: 1;
transition: none ;

}
   background-color: #0b549425;
    box-shadow:0px 3px 25px 0px rgba(0, 0, 0, 0.233);

    box-shadow: 0px 11px 38px -8px rgba(0,0,0,0.39);
    border: 1px solid #0a688727;

    z-index: 100;
transition: .1s ease-out;

scale: 1.01;
  }
    }

    a{
        color: black;
          font-weight: lighter;
          text-decoration: none;
        }


}


//towns

.Town_Table{
    width: 90%;
    display: grid;
    a{
        color: black;
          font-weight: lighter;
          text-decoration: none;
        }
    .Town_Wrapper{
        border: .5px solid #0a688727;
        width: 80%;
        display: grid;
        grid-template-columns: repeat(auto-fit, 250px); /* set a fixed width of 200px for each column */
        border-radius: 5px;
   font-size: 14px;
      place-content: center;
        font-weight: lighter;
        grid-template-areas: 
        "Town_Id Town_Name";
        padding: 0;
        max-height: 40px;
        margin: 0 auto;

        .Town_Id{
            display: grid;
            grid-area: Town_Id;
        }
        .Town_Name{
            display: grid;
            grid-area: Town_Name;
        }

        &.Title{
            background-color: #0a6887;
            border-radius: 5px;
            color: white;
}

z-index: 10;
transition: 1s ease-in;
&:hover{
    &.Title{
        background-color: #0a6887;
        border-radius: 5px;
        color: white;
        scale: 1;
transition: none ;

}
   background-color: #0b549425;
    box-shadow:0px 3px 25px 0px rgba(0, 0, 0, 0.233);

    box-shadow: 0px 11px 38px -8px rgba(0,0,0,0.39);
    border: 1px solid #0a688727;

    z-index: 100;
transition: .1s ease-out;

scale: 1.01;
  }
    }

}