@import "../../../../styles/variables";
/* Modal Overlay */
.ReactModal__Overlay {

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
  }
  
  /* Modal Content */
  .ReactModal__Content {
overflow: auto;

    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    border: none;
    border-radius: 8px;
    padding: 20px;
    max-width: 90wv;
    width: 90%;
    max-height: 80vh;
    margin-top: 50px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Modal Close Button */
  .CategoryMode button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #555;
    cursor: pointer;
    font-size: 18px;
    padding: 0;
  }
  
  /* Modal Header */
  .CategoriesList h3 {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
  }
  
  /* Modal Search Bar */
  .CategoriesList .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .CategoriesList .Search_bar {
    flex: 1;
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .CategoriesList .refresh {
    background: none;
    border: none;
    color: #555;
    cursor: pointer;
    font-size: 18px;
    padding: 0;
  }
  
  /* Modal Category List */
  .Categorylist_item_Wrapper {
    display: grid;
    grid-gap: 10px;
  }
  
  .Categorylist_item {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 10px;
    padding: 10px;
    cursor: pointer;
    border: 2px solid #ccc;
    border-radius: 4px;
    &.Model{
    border-color: #00c479;

    }
    &.Category{
      border-color: #0077ff;
  
      }
      &.Brand{
        border-color: #ff0000;
    
        }
  }
  
  .Categorylist_item._selected {
  //  border-color: #007bff;
    background-color: rgba(54, 54, 54, 0.137);
    box-shadow: $shadow;
  }
  
  .Categorylist_item img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .Count {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  /* Modal "End of Categories" item */
  .Categorylist_item:last-child {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  
  /* Loading Spinner */
  .LoadingSpinner {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border-top: 2px solid #007bff;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  


  .CategoriesList{
    .controls{
        display: grid;
        grid-template-columns: 1fr .2fr;
        button.refresh {
            color: red;
          }
    }
    .Search_bar{
        border: none;
        width: 80%;
        border-radius: 0px;
        text-align: center;
        margin: 10px auto;
        display: grid;
        border-bottom: 2px solid $Primary-Theme-Green;
    }

   h3{
        color: $Primary-Theme-Green;
        text-align: center;
        font-weight: 800;
    }
    width: 80%;
    margin: 5px auto;
    height: 100vh;

    &::-webkit-scrollbar {
        width: 0px;
        background-color: transparent;

      }
      &::-webkit-scrollbar-thumb {
        border-radius: 0px;
      }
      
      .Categorylist_item_Wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Automatic column sizing */
        grid-auto-rows: auto; /* Automatic row sizing */
        gap: 10px; /* Optional: Add space between items */
        overflow-x: auto; /* Enable horizontal scrolling if needed */
        height: auto; /* Optionally, set a fixed height or remove it for natural height */
      }
      
      


    .Categorylist_item{
        margin: 18px 0px;
        width: 90%;
        margin: 10px auto;
        max-width: 300px;
        max-height: 250px;
        border-radius: 5px;
        
        display: grid;
        padding: 5px;
        grid-template-columns: .1fr 1fr 1fr;
        grid-template-areas:
        "CategoryLogo CategoryName CategoryName"
        "FavoriteIcon Count Count"
        ;
        transition: border-color 0.2s ease-in-out;

        &._selected{
         //   border: solid 2px $Primary-Theme-Green;
            transition: border-color 0.3s ease-in-out;
        }
.FavoriteIcon{
  grid-area: FavoriteIcon;
}
.CategoryName{
grid-area: CategoryName;
font-weight: 700;
font-size: 15px;
text-align: center;

}
.CategoryLogo{
grid-area: CategoryLogo;
width: 50px;
height: 50px;
border-radius: 50%;

img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* add this to ensure the image fits inside the container */
  }
}
.ProductCount{
grid-area: ProductCount;
font-weight: 600;
font-size: 12px;
color: gray;
text-align: center;

}
.ProductArchCount{
grid-area: ProductArchCount;
font-weight: 600;
font-size: 12px;
color: gray;
text-align: center;

}
.Count{
grid-area: Count;
display: grid;
grid-template-columns: 1fr 1fr;
grid-template-areas: 
"ProductCount ProductArchCount";
font-weight: 600;
font-size: 12px;
color: gray;
text-align: center;

}

    }
}





.Collection_Model{
max-width: 90vw;
border: solid 2px grey;

}


//collection page 
.Collection_Wrapper{
//  background-color: #007bff;
  display: grid;
  height: 100vh;

  .menu_collection{
   // background-color: aquamarine;
    display: grid;
    width: 100%;
    max-width: 450px;
    max-height: 100px;
  //  background-color: #007bff;
    margin: 0 auto;
grid-template-columns: 4fr 2fr ;
grid-template-rows: 1fr 1fr;
grid-template-areas: 
"menu  Export "
"result_kpi  showing_ "
;
.showing_{
 //background-color: #555;
  z-index: 12;
}
    .menu{
    display: grid;
    grid-area: menu;
     //background-color: #007bff;
    padding: 0;
    margin: 0;

    }

      .Export{
        display: grid;
        grid-area: Export;
        place-content: center;
        
      //    background-color: #555;
        }
        .result_kpi{
        grid-area: result_kpi;
    
        //background-color: antiquewhite;
        }
   

  }
}






$anim-duration: 5; // in seconds
$anim-speed: 0.65; // in seconds

:root {
 

  --shine-degree: 120deg;
  --shine-color: rgba(131, 154, 255, 0.274);
  --shine-effect: linear-gradient(
    var(--shine-degree),
    transparent,
    var(--shine-color),
    transparent
  );
  --shine-transition: all #{$anim-speed}s ease-in-out;
}

.btn {
  position: relative;
  overflow: hidden; // debug

  // last element should not have margin
  &:not(:last-child) {
    margin-bottom: 3rem;
  }
  font-size: 1.1rem;





  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--shine-effect);
  }
}



.btn-anim {
  &::before {
    animation: shine #{$anim-duration}s ease-in-out infinite;
  }
}

@keyframes shine {
  0% {
    left: -100%;
    transition-property: left;
  }
  #{($anim-speed / ($anim-duration + $anim-speed) * 100%)},
  100% {
    left: 100%;
    transition-property: left;
  }
}

