
/* Modal styles */

.ArchMenu_Background {
    // z-index: 10;
    z-index: 200;
   
     position: fixed;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     background-color: rgba(0, 0, 0, 0.5);
     display: flex;
     justify-content: center;
     align-items: center;
   }
   
   .ArchMenu_content {
    display: grid
    ;
    background-color: rgb(255, 255, 255);
    // background-color: rgb(167, 0, 0);
     color: black;
     padding: 20px;
     border-radius: 4px;
     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
     max-width: 100%;
     width: 80vw;
     height: 50vh;
     max-height: 100%;
     overflow: auto;
     top: 0;


     display: grid;

     grid-template-columns: 1fr 3fr;
     
     .info_{
     // background-color: aqua;
     }

     ._details{
     // background-color: beige;
     }
     
   }

   @media (max-width: 768px) {

    .ArchMenu_content {
      grid-template-columns: 1fr;
      grid-template-rows: 100px 1fr;
    }

   }.titlename{
    &._black{
      color: black;
    }
   }