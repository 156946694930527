.Payemt_modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    max-width: 500px;
    margin: 0 auto;
    
    .modal-heading {
    margin-top: 0;
    }
    
    .modal-label {
    font-weight: bold;
    margin-bottom: 5px;
    }
    
    .modal-value {
    margin-top: 0;
    }
    }
    

.modal-overlay{
    width: 100%;
    overflow: auto;

    &._customers{
   background-color: rgba(238, 238, 238, 0.178);

    width: 100%;
    }
    .modal{
        width: 80vw;
      //  background-color: antiquewhite;
        overflow: auto;
    }
 
}