.cheque-table {
    width: 90%;
    margin: 0 auto;
    border-collapse: collapse;
   
    th,
    td {
      padding: 8px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }
  
    th {
      background-color: #f2f2f2;
      font-weight: bold;
    }
  
    tr:nth-child(even) {
      background-color: #f9f9f9;
    }


    tbody {
        tr.Realised {
          background-color: #9dff9d3f;
          border-bottom: rgb(0, 212, 0) 3px solid;
        }
        tr.Bounced{
            background-color: #ff9d9d3f;
            border-bottom: rgb(255, 0, 0) 3px solid;
        }
      }
  }
  