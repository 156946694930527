@import "../../styles/variables";

.SalesOrderDashboard{
    display: grid;
    grid-template-columns: 1fr 3fr;

    grid-template-areas: 
    "OrderList OrderDetail"
    ;

    .OrderList{

       h3{
            color: $Primary-Theme-Green;
            text-align: center;
            font-weight: 800;
        }
        width: 80%;
        margin: 5px auto;

        .orderlist_Item{
            margin: 18px 0px;
        width: 100%;

            border-radius: 5px;
            box-shadow: $shadow;
            display: grid;
            padding: 15px;
            grid-template-columns: 2fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            grid-template-areas:
            "Customer_Name Status Created_date"
            "SalesOrderId Est_total Sales_rep"
            ;
            transition: border-color 0.2s ease-in-out;

            &._selected{
                border: solid 2px $Primary-Theme-Green;
                transition: border-color 0.3s ease-in-out;
            }



            .Customer_Name{
                grid-area: Customer_Name;
                font-weight: 750;
                font-size: 13px;
            }
            .Status{
                grid-area: Status;
                font-weight: 800;
                font-size: 10px;
            }
            .Created_date{
                grid-area: Created_date;
                font-weight: 800;
                font-size: 10px;
                width: 100%;
                text-align: right;
            }
            .SalesOrderId{
                grid-area: SalesOrderId;
                font-weight: 600;
                font-size: 12px;
                color: gray;
            }
            .Est_total{
                grid-area: Est_total;
                font-weight: 600;
                font-size: 12px;
                color: gray;
            }

            .Sales_rep{
                grid-area: Sales_rep;
                font-weight: 600;
                font-size: 12px;
                color: gray;
                text-align: right;

}
        }
    }


   
}

.Order-tab-OAM{

     color: $Primary-Theme-Green;
font-weight: 800;


    & .Mui-selected .MuiTabIndicator-root {
        background-color: #ff0000;
      }


      & .MuiTabs-indicator {
        background-color: #ff0000;
      }

    & .MuiTab-root.Mui-selected {
    color: #ff0000;
    
      }
}

.Order-tab-Maruti {
    color: red;
    font-weight: 800;
}
.Order-tab-Harvestor {
    color: rgb(33, 143, 0);
    font-weight: 800;
    & .MuiTab-root.Mui-selected {
        color: #ff0000;
        
          }
}
.Order-tab-Tafe_OE {
    color: rgb(111, 0, 255);
    font-weight: 800;
}
.Order-tab-Simpsons_OE {
    color: rgb(255, 115, 0);
    font-weight: 800;
}