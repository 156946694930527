.Home_Container{
    //background-color: azure;
   // width: 90vw;
    width: 100vw;
    max-width: 100vw;
    margin: 0 auto;
    transition: max-height 0.3s ease-in-out;
}

.products_dash{
   //    background-color: aqua;
    margin: 0 auto;
}




  
  .home-tab-label {
    margin: 10px 0;
  }


.btn_area{
  //  background-color: aqua;
    .home_menu_Button{
      display: grid;
      margin: 0 auto;
     // background-color: bisque;
    }
}

