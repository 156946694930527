.loader{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    
    display: flex;
    flex-direction: column;
    align-items: center;
    
    &-wrapper{
        transition: opacity .3s ease-in-out;

        position: fixed;
        top: 0%;
        left: 0%;

        width: 100%;
        height: 100%;

        //background-color: rgba(white, .85);
        background-color: rgba(white, .975);

        z-index: 1000;

        &.hidden{
          display: none;
    
          svg{
              animation: none;
          }
        }
        &.disabled{
          opacity: 0;
        }

        &.enabled{
            display: block;
        }
    
        svg{
          animation: spin .75s normal linear infinite;
        }
        p{
          margin-top: 20px;
          font-weight: 500;
        }
    }

    svg{
        fill: $Primary;

        width: 75px;
        height: 75px;

        @media screen and (max-width: $mobileMax) {
            width: 50px;
            height: 50px;
        }
        @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
        }
        @media screen and (min-width: $desktopMin) {
        }
    }

    @keyframes spin {
        0% {
            transform: rotate3d(0, 0, 1, 0deg);
          }
          25% {
            transform: rotate3d(0, 0, 1, 90deg);
          }
          50% {
            transform: rotate3d(0, 0, 1, 180deg);
          }
          75% {
            transform: rotate3d(0, 0, 1, 270deg);
          }
          100% {
            transform: rotate3d(0, 0, 1, 360deg);
          }
    }
}