@import './_variables.scss';
@import './_global.scss';
@import './_keyframes.scss';


//Components
@import './components/_footer.scss';
//remove in folder only @import './components/_header.scss';
@import './components/_nav.scss';
@import './components/_addtocartBtn.scss';
@import './components/_ImageSilder/_imageSlider.scss';
//remove in folder only@import './components/Image/imagerow';

@import './components/_loader.scss';
@import './components/CC_Loading';
@import './components/_custom-alert.scss';
@import './components/_BrandCard.scss';
@import './components/_productArch.scss';
//remove in folder only@import './components/_CartModal.scss';
@import './components/_cartcontainer.scss';
@import './components/_CartView.scss';
@import './components/_Scrollbar.scss';
@import './components/_button.scss';
@import './components/_FileUpload.scss';
@import './components/Director_Dashboard/Director_Dashboard';
@import './components/SalesOrderPage';

@import './components/Order/Order';
@import './components/Order/Invoice';


@import './components/State_selector_orders';

//Pages
@import './pages/_register.scss';
@import './pages/_login.scss';
@import './pages/_dashboard.scss';
//@import './pages/_category.scss';
@import './pages/_CustomerList.scss';
@import './pages/_orderview.scss';




//home 
@import './Home/Home';


@import './components/Payments/Payments';


  //paymentovucherstyles
  @import '../components/Payment/Style/Style.scss';


  //prodcutpage
  @import '../components/Product/Style//Style.scss';

  :root {
    --background-color: white;
  }
  
  [data-theme='dark'] {
    --background-color: black;
  }
  
  .content{
    --background-color: white;

  &.dark{
    background-color: #333333;

  }
  }




  