.SalesOrder_V2_dashboard{
   // background-color: aqua;
}


.orderlist-container {
    overflow-x: auto;
  
    table {
      width: 100%;
      border-collapse: collapse;
  
      th,
      td {
        padding: 10px;
  
        &:first-child {
          min-width: 200px;
       //   background-color: #0a6887;
        }
  
        &:nth-child(2) {
     //     background-color: #2d870a;

          min-width: 120px;
        }
  
        &:nth-child(3) {
       //   background-color: #870a44;
          min-width: 160px;
        }
  
        &:nth-child(4){
          min-width: 50px;

        }
        &:nth-child(5) {
       //   background-color: #7a870a;
          min-width: 100px;
        }
      }
    }
  }
  

  .orderlist_Item{
    
    margin: 0 auto;
width: 90%;
border-radius: 5px;
//box-shadow:0px 3px 25px 0px rgba(0, 0, 0, 0.233);
display: grid;
place-content: center;
grid-template-columns: repeat(auto-fit, 250px); /* set a fixed width of 200px for each column */
grid-template-columns:75px 75px 75px 350px 150px 250px 50px;
grid-template-areas: 
"Urgent salesOrderId created_Date_Time customer_name total salesRep Status"
;
.Urgent{
  font-weight: 800;
}
z-index: 10;
transition: 1s ease-in;
border: .5px solid #0a688727;
&:hover{


  background-color: #0b549425;

  box-shadow: 0px 11px 38px -8px rgba(0,0,0,0.39);
  border: 1px solid #0a688727;

  z-index: 100;
transition: .25s ease-out;

scale: 1.01;

&.Title{
  background-color: #0a6887;
  border-radius: 5px;
  color: white;
  scale: 1;
transition: none ;

}
}



font-weight: lighter;
font-size: 14px;


.salesOrderId{
  grid-area: salesOrderId;
display: grid;
}
.created_Date_Time{
  grid-area: created_Date_Time;
display: grid;
}
.customer_name{
  grid-area: customer_name;
display: grid;
}
.total{
  grid-area: total;
display: grid;
}
.salesRep{
  grid-area: salesRep;
display: grid;
}

.Status{
  grid-area: Status;
display: grid;
}

a{
color: black;
  font-weight: lighter;
  text-decoration: none;
}

//background-color: #c4e4f43b;

    &.Title{
      background-color: #0a6887;
  border-radius: 5px;
  color: white;
  scale: 1;
transition: none ;
    }



  }

  /* Media query for mobile screens (adjust max-width as needed) */
@media screen and (max-width: 768px) {
  .orderlist_Item {
    grid-template-columns: 7.89% 7.89% 36.84% 15.79% 26.32% 5.26%;
  }
}



/* Media query for screens between 480px and 320px */
@media screen and (max-width: 480px) {
  .orderlist_Item {
    grid-template-columns: 20% 20% 30% 15% 15% 5%;
  }
}

/* Media query for screens smaller than 320px */
@media screen and (max-width: 320px) {
  .orderlist_Item {
    grid-template-columns: 30% 30% 30% 5% 5% 0%;
  }
}

/* Media query for screens with a minimum width of 280px and minimum height of 653px */
@media screen and (max-width: 280px) and (min-height: 653px) {
  .orderlist_Item {
    grid-template-columns: 40% 40% 10% 5% 5% 0%;
  }
}