
html{
    scroll-behavior: smooth;
    font-family: "Poppins";
    font-size: 16px;
    @media screen and (max-width: $mobileMax) {
        font-size: 14px;
    }

    &.noscroll{
        overflow: hidden;
    }
}

body{
    margin: 0 auto;
    min-height: 100vh;
    width: 100vw;
    //max-width: 1920px;
    overflow-x: hidden;
}

#root{
    display: flex;
    flex-direction: column;
    
    min-height: 100vh;
}

.content{
    display: grid;

    position: relative;

    background-color: $Background_Light;
}
.content-child{
    animation: page-change .5s ease-in-out forwards;
}

iframe{
    outline: none;
    border: none;
    box-shadow: $shadow;
}
p{
    line-height: 130%;
    margin: 0;
    margin-block: 0;
    font-family: 'Poppins';

    &.danger{
        color: $danger;
    }
}
strong{
    &.danger{
        color: $danger;
    }
}
label{
    font-family: 'Poppins';
    font-size: 16px;
    
    @media screen and (max-width: $mobileMax) {
        font-size: 14px;
    }
}
h1,h2,h3,h4{
    font-family: "Poppins";
    margin: 0;
    font-weight: normal;
}
h1{
    font-family: "Poppins";
    font-weight: 700;
    
    margin: 0;
    margin-block: 0;
    
    @media screen and (max-width: $mobileMax) {
        font-size: 34px;
    }
    @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
        font-size: 40px;
    }
    @media screen and (min-width: $desktopMin) {
        font-size: 44px;
    }
}
h2{
    font-family: "Poppins";
    font-weight: 600;

    @media screen and (max-width: $mobileMax) {
        font-size: 23px;
    }
    @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
        font-size: 30px;
    }
    @media screen and (min-width: $desktopMin) {
        font-size: 32px;
    }
}
h3{
    font-family: "Poppins";
    font-weight: 500;

    @media screen and (max-width: $mobileMax) {
        font-size: 20px;
    }
    @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
        font-size: 22px;
    }
    @media screen and (min-width: $desktopMin) {
        font-size: 24px;
    }
}
h4{
    font-family: "Poppins";
    font-weight: 500;
}
ul{
    padding: 0;
    margin: 0;
}
li{
    font-family: 'Poppins';
}
input{
    font-family: 'Poppins';

    &[type=button],
    &[type=submit]{
        outline: none;
        border: none;
        padding: 0;

        cursor: pointer;
    }
} 
select,
input,
textarea,
.search-select > div{
    font-family: 'Poppins';
    font-size: 16px;
    font-weight:normal;
    line-height: 100%;

    box-sizing: border-box;

    padding: 10px 15px;
    
    border-radius: 10px;
    outline: none;
    border: 1px solid $unfocused-grey;

    transition: .1s ease-in-out;

    margin: 0;
    
    @media screen and (max-width: $mobileMax) {
        font-size: 14px;
    }

    &:disabled{
        cursor: not-allowed;
        opacity: 1;

        background-color: $lightest-grey;
        border-color: $lightest-grey;
        color: $main-grey;
    }
    &::placeholder{
        color: $unfocused-grey;
    }
        
    input:not([type=submit]),
    input:not([type=button]){
        &:hover,
        &:focus{
            &:not(:disabled){
                border-color: $Primary;
            }
        }
    }

    option{
        font-family: 'Poppins';
    }
}
.search-select > div{
    padding: 2.5px 5px;
}
button,
input[type=submit],
input[type=button]{
    cursor: pointer;

    padding: 15px 30px;

    font-size: 16px;
    line-height: 100%;
    font-weight: 500;
    max-height: 45px;

    background-color: white;
    color: $main-grey;
    border: 1px solid $main-grey;
    
    transition: all .1s ease-in-out;

    @media screen and (max-width: $mobileMax) {
        font-size: 14px;
    }

    &:disabled{
        cursor: not-allowed;

        border: 1px solid $unfocused-grey !important;
        background-color: $unfocused-grey !important;
        color: white !important;
    }

    &:hover{
        opacity: 90%;
    }

    svg{
        display: inline;
    }
}
a{
    text-decoration: none;
    font-family: "Poppins";

    &:disabled{
        cursor: not-allowed;
    }
}
button{
    outline: none;
    border: none;
    background: none;
    margin: 0;
    border-radius: 20px;

    font-family: "Poppins";

    &:disabled{
        cursor: not-allowed;
    }
}
button{
    text-decoration: none;
    font-weight: 600;

    cursor: pointer;
}
fieldset{
    border: none;
    padding: 0;
    margin: 0;

    width: 100%;
}
svg{
    display: block;
}
table{
    font-family: 'Poppins';
    font-size: 16px;
    
    @media screen and (max-width: $mobileMax) {
        font-size: 14px;
    }

    th{
        text-align: start;

        font-weight: 500;
        line-height: 100%;
        font-size: 17px;
        
        @media screen and (max-width: $mobileMax) {
            font-size: 15px;
        }
    }
}

.inline-inputs{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;

    width: 100%;

    @media screen and (max-width: $mobileMax) {
        grid-template-columns: repeat(1, 1fr);
        //column-gap: 20px;
    }
}
.labeled-input{
    position:relative; 

    select,
    input,
    textarea{
        padding: 12.5px 10px;
        line-height: 100%;
        
        font-weight:normal;

        border: none;
        border-radius: 0;
        border-bottom: 3px solid $unfocused-grey;

        width: 100%;
        
        &:focus{
            border-bottom-color: $Primary;
            
            ~ label{
                color: $Primary;
            }
        }

        @media screen and (max-width: $mobileMax) {
            padding: 10px 7.5px;
        }
        @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
        }
        @media screen and (min-width: $desktopMin) {
        }
    }

    label{
        pointer-events:none;

        width: 100%;
        
        color: $unfocused-grey;
        font-weight:normal;

        position:absolute;
        left: 10px;
        top: 0%;
        transform: translateY(-50%);

        transition: 0.2s ease;

        @media screen and (max-width: $mobileMax) {
            left: 7.5px;
        }
    }
}
.input{
    position:relative; 
    margin-bottom: 15px;

    text-align: start;

    select,
    input,
    textarea,
    .search-select{
        //margin-top: 5px;

        &:not([type=checkbox]){
            width: 100%;
        }
    }

    label,
    p{
        display: block;
        width: 100%;
        
        padding-left: 3px;

        font-size: 90%;
        font-weight: 500;

        color: $main-grey;
    }
    p{
        font-size: 14px;
        font-weight: 400;
        
        margin-top: 2.5px;
        
        @media screen and (max-width: $mobileMax) {
            font-size: 12px;
        }
    }

    &.image-edit-input{
        img{
            width: 100%;
            height: auto;
            object-fit: contain;
            
            @media screen and (min-width: $desktopMin) {
                width: 50%;
            }
        }
    }
}
.floating-input{
    position:relative; 
    height: max-content;

    margin-bottom: 15px;

    select,
    input{
        padding: 12.5px 10px;
        line-height: 100%;
        
        font-weight:normal;

        border: none;
        border-radius: 0;
        border: 1px solid $unfocused-grey;
        border-radius: 10px;

        width: 100%;

        &::placeholder{
            visibility: hidden;
        }
        
        &:focus{
            border-color: $Primary;

            &::placeholder{
                visibility: visible;
            }
            
            ~ label{
                color: $Primary;
                font-size: 90%;

                top: 0%;
            }
        }

        @media screen and (max-width: $mobileMax) {
            padding: 10px 7.5px;
        }
        @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
        }
        @media screen and (min-width: $desktopMin) {
        }
    }

    select:not([value=""]):valid,
    input:not(:placeholder-shown) {
        border-color: $Primary;

        ~ label{
            color: $Primary;
            font-size: 90%;

            top: 0%;
        }
    }

    label{
        pointer-events:none;
        
        background-color: white;
        color: $unfocused-grey;
        font-weight:normal;
        line-height: 100%;

        position:absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);

        transition: 0.2s ease;

        width: max-content;
        padding: 0 7.5px;

        @media screen and (max-width: $mobileMax) {
            left: 10px;
        }
    }
}
.radio-input{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    input{
        margin-right: 5px;
    }
}

.inline-btns{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    width: 100%;

    @media screen and (max-width: $mobileMax) {
        flex-direction: column;
    }
    @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
    }
    @media screen and (min-width: $desktopMin) {
    }

    button,
    input[type=submit],
    input[type=button]{
        border-radius: 15px;

        font-weight: 500;
        line-height: 100%;

        transition: .3s ease-in-out;

        font-size: 18px;

        @media screen and (max-width: $mobileMax) {
            width: 100%;
            font-size: 16px;
        }
        @media screen and (min-width: $tabletMin) {
            width: 50%;

            &:not(:last-of-type){
                margin-right: 10px;
            }
        }
    }
}
.stacked-btns{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    width: 100%;

    @media screen and (max-width: $mobileMax) {
        flex-direction: column;
    }
    @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
    }
    @media screen and (min-width: $desktopMin) {
    }

    button,
    input[type=submit],
    input[type=button]{
        border-radius: 15px;
        width: 100%;

        font-size: 18px;
        font-weight: 500;
        line-height: 100%;

        transition: .3s ease-in-out;

        @media screen and (max-width: $mobileMax) {
            font-size: 16px;
        }
        @media screen and (min-width: $tabletMin) {
            &:not(:last-of-type){
                margin-bottom: 10px;
            }
        }
    }
}
.live-search{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;
    position: relative;

    background-color: white;
    border-radius: 10px;

    @media screen and (max-width: $mobileMax) {
        flex-direction: column;
        align-items: flex-start;

        width: 100%;
    }

    &.open{
        box-shadow: $shadow;
        
        .live-search-results{
            position: absolute;
            top: 100%;

            background-color: white;
            box-shadow: $shadow;
            border-radius: 0 0 10px 10px;
            border: 1px solid $Primary;
            border-top: none;

            height: max-content;
            
            padding: 10px 20px !important;
        }

        .live-search-input{
            box-shadow: none !important;

            input{
                border-radius: 10px 10px 0 0;
                border: 1px solid $Primary;
                border-bottom: none;

                transition: none !important;
            }
        }
    }

    &-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;

        position: relative;
    }
    &-input{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        text-align: start;

        font-weight: 500;
        border-radius: 10px;
        color: rgba($main-grey, .8);
        background-color: white;

        z-index: 3;
        width: 100%;

        input{
            width: 100%;
            padding: 12.5px 10px;

            @media screen and (max-width: $mobileMax) {
                padding: 10px 7.5px;
            }
        }
        button{
            position: absolute;
            top: 0%;

            height: 100%;
            padding: 10px 15px;

            transition: .2s ease-in-out;

            &:nth-of-type(1){
                right: 0;
                
                &:hover{
                    svg{
                        fill: $danger;
                    }
                }

                svg{
                    fill: $main-grey;

                    transition: .2s ease-in-out;

                    height: 15px;
                }
            }
        }
    }
    &-results{
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        box-sizing: border-box;
        width: max-content;

        padding: 0 20px;
        height: 0;
        width: 100%;
        overflow: hidden;

        z-index: 2;
        
        max-height: 25vh;
        overflow-y: auto;

        @media screen and (max-width: $mobileMax) {
            width: 100%;
        }

        &-result{
            position: relative;
            width: 100%;
            box-sizing: border-box;
            padding: 5px 0;

            p{
                max-width: 75%;
            }

            button{
                position: absolute;
                right: 0%;
                top: 50%;

                transform: translateY(-50%);

                padding: 0;
                color: $Primary;
                font-weight: 500;

                svg{
                    fill: $Primary;

                    height: 20px;
                    width: auto;
                }
            }
        }

        &-minimize{
            position: absolute;
            right: 20px;

            font-weight: 500;
            opacity: 80%;
            font-size: 90%;

            padding: 0;

            align-items: center;
        }

        &-info{
            width: 100%;

            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 10px;
            margin: 25px 0 5px;

            &-heading{
                font-size: 90%;
                color: $unfocused-grey;
            }
            &-count{
                font-size: 75%;
                color: $unfocused-grey;
    
                width: 100%;
                text-align: end;
            }
        }
    }
}
.dates-picker{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: max-content;
    position: relative;

    background-color: white;
    border-radius: 20px;

    @media screen and (max-width: $mobileMax) {
        flex-direction: column;
        align-items: flex-start;

        width: 100%;
    }

    &.open{
        box-shadow: $shadow;
        
        .dates-picker-inputs{
            position: absolute;
            top: 100%;

            background-color: white;
            box-shadow: $shadow;
            border-radius: 0 0 20px 20px;

            height: max-content;
            
            padding: 10px 20px 20px !important;
        }

        .dates-picker-toggle{
            box-shadow: none !important;
            border-radius: 20px 20px 0 0;

            transition: none !important;

            z-index: 3;

            svg{
                &:nth-of-type(2){
                    rotate: -90deg;
                }
            }
        }
    }

    &-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;

        position: relative;

        &.reports{
            margin-bottom: 30px;
    
            @media screen and (max-width: $mobileMax) {
                margin-bottom: 20px;
            }
        }
    }
    &-toggle{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        text-align: start;

        font-weight: 500;
        border-radius: 15px;
        color: rgba($main-grey, .8);
        background-color: white;
        box-shadow: $button-shadow;

        z-index: 3;

        svg{
            transition: .3s ease-in-out;
            fill: rgba($main-grey, .8);

            &:nth-of-type(1){
                margin-right: 10px;
            }
            &:nth-of-type(2){
                width: 7.5px;
                margin-left: 20px;
                rotate: 90deg;
            }
        }

        @media screen and (max-width: $mobileMax) {
            width: 100%;
        }
    }
    &-inputs{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        box-sizing: border-box;
        width: max-content;

        padding: 0 20px;
        height: 0;
        width: 100%;
        overflow: hidden;

        z-index: 2;

        @media screen and (max-width: $mobileMax) {
            width: 100%;
        }

        fieldset{
            margin-bottom: 15px;
        }

        &-pickers{
            p{
                margin: 0 5px 2.5px;
                text-align: start;
            }
            input[type="date"]{
                width: 100%;
                
                &:not(:last-of-type){
                    margin-bottom: 10px;
                }
            }
            input[type="button"]{
                width: 100%;
                margin-top: 10px;
    
                box-shadow: $button-shadow;
                background-color: $Primary;
                color: white;
            }
        }
    }
}


.modal{ 
    box-shadow: $shadow; 
    border-radius: 15px;

    background-color: white;

    padding: 30px;                    
    width: 75%;

    &-wrapper{
        position: fixed;
        top: 0%;
        left: 0%;
        z-index: 999;

        display: flex;
        justify-content: center;
        align-items: center;
        
        width: 100%;
        height: 100%;

        background-color: rgba(white, .9);
    }
    
    &-top{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 20px;

        h3{
            margin-bottom: 0 !important;
        }

        button{
            position: relative !important;
            top: 0% !important;
            right: 0% !important;
    
            padding: 0 !important;

            border: none !important;
            background-color: transparent !important;
            
            &:hover{
                background-color: inherit !important;
            }
    
            svg{
                fill: $main-grey !important;
                transform: rotate(180deg) !important;
                height: 17.5px !important;
            }
        }
    }
}

.collapsible{

}

.input-search{
    box-shadow: $shadow; 
    border-radius: 15px;

    background-color: white;

    padding: 30px;
    width: 75%;

    &-wrapper{
        position: fixed;
        top: 0%;
        left: 0%;
        z-index: 2;

        display: flex;
        justify-content: center;
        align-items: center;
        
        width: 100%;
        height: 100%;

        background-color: rgba(white, .8);
    }

    p{
        margin-bottom: 10px;
    }
    
    &-top{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 20px;

        h3{
            margin-bottom: 0 !important;
        }

        button{
            position: relative;
            top: 0%;
            right: 0%;
    
            padding: 0;
    
            svg{
                fill: $main-grey;
                transform: rotate(180deg);
                height: 17.5px;
            }
        }
    }

    &-selectedvalue{
        font-size: 16px !important;
        font-weight: normal !important;
        line-height: 100% !important;

        margin: 5px 0 !important;
    }

    form{
        box-shadow: none !important;
        padding: 0 !important;
    }
}



.password-requirements{
    @media screen and (max-width: $mobileMax) {
        margin-top: 30px;
    }
    @media screen and (min-width: $tabletMin) {
        margin-bottom: 25px;
    }

    li{
        display: grid;
        grid-template-columns: 16px auto;
        column-gap: 5px;
        align-items: center;

        list-style: none;
        line-height: 115%;

        margin-bottom: 5px;

        color: $unfocused-grey;

        transition: .3s ease-in-out;

        svg{
            fill: $unfocused-grey;

            height: 16px;
            width: 16px;
        }

        @media screen and (max-width: $mobileMax) {
        }
        @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
        }
        @media screen and (min-width: $desktopMin) {
        }

        &.failed{
            color: $danger;

            svg{
                fill: $danger;
            }
        }
        &.passed{
            color: $Primary;

            svg{
                fill: $Primary;
            }
        }
    }
}