nav{
    position: relative;

    .nav-user{
        position: relative;

        button{
            cursor: pointer;

            position: relative;
            z-index: 2;
            padding: 12.5px;

            transition: all .3s ease-in-out;
            transition-delay: .1s;

            svg{
                height: 24px;
                
                transition: .2s ease-in-out;
                
                fill: $main-grey;
            }

            &:hover{
                svg{
                    fill: $Primary;
                }
            }

            &.open{
                background-color: white;
                border-radius: 15px 15px 0 0;
                box-shadow: $button-yoffset-shadow;

                transition-delay: 0s;

                svg{
                    fill: $Primary;
                }
            }
        }

        &-dropdown{
            //display: none;

            box-sizing: border-box;

            position: absolute;
            top: 100%;
            //right: -5px;
            right: 0;
            z-index: 1;
            overflow: hidden;

            border-radius: 20px 0px 20px 20px;
            box-shadow: $button-shadow;
        
            width: 0;
            height: 0;
            padding: 0;
            transition: width 0.3s ease-in-out, padding 0.3s ease-in-out, height 0.2s ease-in-out;
        
            background-color: white;

            &.open{
                display: block;
        
                width: 100%;
                height: max-content;
                padding: 20px 25px;

                transition: height 0.3s ease-in-out, padding 0.3s ease-in-out, width 0.2s ease-in-out;

                @media screen and (max-width: $mobileMax) {
                    width: 50vw;
                }
                @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
                    width: 25vw;
                }
                @media screen and (min-width: $desktopMin) {
                    width: 10vw;
                }

                @at-root {
                    .nav-user{
                        button{
                            svg{
                                fill: $main-grey;
                            }
                        }
                    }
                }
            }

            a{
                display: block;
                line-height: 100%;

                color: $Primary;  

                transition: .2s ease-in-out;

                &:not(:last-of-type){
                    margin-bottom: 10px;
                }

                &:hover{
                    opacity: 90%;
                }
            }
        }
    }
}