




  .sectorwrapper{
    &.dark {
      background-color: #333333;
      color: #fff;

      .sector{
        background-color: #1b1b1b;
        color: #fff;
  
        &.Names{
          border: 1px rgb(0, 223, 0) solid;
        }
        &.Stock_rate{
          border: 1px rgb(204, 0, 0) solid;
        
        }
      }

      &.Names{
        .addtocart{
          .addtocartBtn{
            color: #fff;
           }
        }
      
      }

      }


    display: grid;
 //   grid-template-columns: auto auto  auto ;
  //   background-color: aquamarine;
    grid-gap: 5px;
   // grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
 
    .sector{
      border-radius: 15px;
      border: .5px rgb(0, 0, 0) solid;
      padding: 15px;

    &:hover{
    //  scale: 1.01;
background-color: #0b549425;
      
box-shadow: 0px 11px 38px -8px rgba(0,0,0,0.39);
border: 1px solid #0a688727;


transition: .25s ease-out;

    }
  
    &.Names{
      border: .5px  green solid;
     // width: 250px;
    //   max-width: 800px;
    }

   &.Stock_rate{
      border: .5px  rgb(128, 0, 0) solid;
    
    }


    }
  }



  @media screen and (max-width: 768px) {
   
  .sectorwrapper{
 // background-color: aquamarine;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));

  }
}


@media screen and (max-width: 290px) {
   
  .sectorwrapper{
//  background-color: rgb(176, 127, 255);
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));

  }
}











//small screen 



.brand_Name {
  margin-bottom: 10px;
}

.productbrandselectlist {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.productbrandselect {
  cursor: pointer;
  margin-bottom: 5px;
}

/* Modal styles */

.modal_brand {
 // z-index: 10;
 z-index: 200;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_brand-content {
  background-color: rgb(255, 255, 255);
  
  color: black;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  width: 80vw;
  height: 50vh;
  max-height: 100%;
  overflow: auto;
  top: 0;
  .Product_history_data{
      width: 100%;
      margin: 0 auto;
      &._table{
      margin: 0 auto;

      }
  }
}


.product_page_wrapper{
 // background-color: #1b1b1b;
 display: grid;
 
 grid-gap: 10px;
 .product_info_page{
 // background-color: #333333;
 margin: o 25px;
 
 }
 .Product_stocklevels{
 margin: 0 25px;
 //background-color: aqua;
 }
}


@media screen and (min-width: 1000px) {

  .product_page_wrapper{
 // background-color: #1b1b1b2c;
    display: grid;
   grid-template-columns: 2fr 1.5fr;
   grid-template-rows: 1fr;
 grid-template-areas: 
 
 "product_info_page Product_stocklevels"
;
    .product_info_page{
  //  background-color: #9b0000;
   //  display: grid;
grid-area:product_info_page ;
    }
    .Product_stocklevels{
    display: grid;
 //   background-color: aqua;
grid-area:Product_stocklevels ;

    }
   }
}

