@import '../../../../styles/variables';
.Create_Payment_Voucher{
    margin: 0 auto;
    width: 80%;

    .Items{
        margin: 0 auto;
        width: 100%;
        border: 3px solid $Primary;
        border-radius: 15px;
        overflow: hidden;
        box-shadow: $shadow;
        table{
            width: 100%;
            .row_item{
                border-bottom: 1px solid #404040;
            }
        }
    }
}


.Add_itemVoucher{
    width: 100%;
    text-align: center;
}

.PaymentMode{
    display: grid;
}

.voucherinfo{

    display: grid;
    grid-template-columns:  1fr 1fr;
    grid-template-areas: 1fr 1fr;
    grid-template-areas: 
    
    "payee PaymentMode"
    "payee selectcategory"
    ;
    .selectcategory{
      //  background-color: rgb(23, 138, 0);
        grid-area: selectcategory;

    }
    .PaymentMode{
      //  background-color: aquamarine;
        grid-area: PaymentMode;
    }
    .payee{
        //background-color: rgb(255, 204, 127);
        grid-area: payee;
    }
}