


.Transaction_Short{
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
   width: 100%;
   
    .totals-section{
        border-radius: 15px;
        border: 1px rgb(0, 0, 0) solid;
         padding: 5px;
         height: min-content;

         &:hover{
       //   scale: 1.01;
    background-color: #0b549425;
          
    box-shadow: 0px 11px 38px -8px rgba(0,0,0,0.39);
    border: 1px solid #0a688727;
    
    transition: .25s ease-out;
    
        }
    }

    .Data_Draw_Chart{
        border-radius: 15px;
        border: 1px rgb(0, 0, 0) solid;
         padding: px;
         display: grid;
        width: 100%;

        &:hover{
        //  scale: 1.01;
    background-color: #0b549425;
          
    box-shadow: 0px 11px 38px -8px rgba(0,0,0,0.39);
    border: 1px solid #0a688727;
    
    transition: .25s ease-out;
    
        }
    }
}

.Transaction_Stock_details{
   // background-color: aquamarine;
    width: 100%;
    margin: 0 auto;
}
.Product_Stock_Info {
   display: flex;
    flex-direction: column;
    align-items: center;
//background-color: antiquewhite;

._Table_Custom{
  width: 90%;
//  max-width: 1080px;
margin: 0 auto;
border-spacing:0;
  //display: grid;
  //place-content: center;
  ._Table_Custom_Row{


    a{
      color: black;
        font-weight: lighter;
        text-decoration: none;
      }
    width: 100%;
    margin: 0 auto;
      z-index: 10;
transition: 1s ease-in;
border: none;
border: 14px solid #0a688727;
border-radius: 15px;
//display: grid;
//place-content: center;
font-weight: lighter;
font-size: 14px;
//grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* set a fixed width of 200px for each column */
&:hover{


  background-color: #0b549425;
  box-shadow: 0px 11px 38px -8px rgba(0,0,0,0.39);
  border: 1px solid #0a688727;

  z-index: 100;
transition: .25s ease-out;

scale: 1.01;

&.Title{
  background-color: #0a6887;
  border-radius: 5px;
  color: white;
  scale: 1;
transition: none ;

}
}
&.Title{
  background-color: #0a6887;
border-radius: 5px;
color: white;
scale: 1;
transition: none ;
}


  }
}
  }
  
 
  
  @media (max-width: 600px) {
    table {
      font-size: 12px;
    }
  }
  
  .in-stock {
    background-color: #0a68877a;
    color: black;
  }
  
  .out-stock {
    background-color:#25d44233;
    color: black;

  }