.ledger_View_Table{
  //  background-color: aqua;
     width: 100%;
    // max-width: 90%;
    display: grid;
    margin: 0 auto;

    font-size: 12px;

    .Ledger_View_Table_Row{
        display: grid;
        width: 100%;
border-radius: 5px;
margin: 0 auto;
font-size: 12px;
place-content: center;
font-weight: lighter;

grid-template-columns: repeat(auto-fit, 125px); /* set a fixed width of 200px for each column */

grid-template-areas: 
"Type voucher_Number Description Debit Credit Current_bal Delete"
;

.Type{
    grid-area: Type;
    display: grid;
}
.voucher_Number{
    grid-area: voucher_Number;
    display: grid;
}
.Description{
    grid-area: Description;
    display: grid;
}
.Debit{
    grid-area: Debit;
    display: grid;
}
.Credit{
    grid-area: Credit;
    display: grid;
}
.Current_bal{
    grid-area: Current_bal;
    display: grid;
}
.Delete{
    grid-area: Delete;
    display: grid;
    .cta{
        color: red;

    }
}

z-index: 10;
transition: 1s ease-in;
border: .5px solid #0a688727;


&.Title{
    background-color: #0a6887;
border-radius: 5px;
color: white;
scale: 1;
transition: none ;
font-weight: lighter;
  }


&:hover{


    background-color: #0b549425;
  
    box-shadow: 0px 11px 38px -8px rgba(0,0,0,0.39);
    border: 1px solid #0a688727;
  
    z-index: 100;
  transition: .25s ease-out;
  
  scale: 1.01;
  
  &.Title{
    background-color: #0a6887;
    border-radius: 5px;
    color: white;
    scale: 1;
  transition: none ;
  
  }
  }

    }
}


/* Apply minimal styling to text inputs */
.input_Type {
    border: none;
    border-bottom: 1px solid #ccc; /* Add a light gray border */
    padding: 8px; /* Add some padding for spacing */
    font-size: 16px; /* Set the font size */
    border-radius: 4px; /* Add rounded corners */
  }
  
  /* Optional: Add focus styles for better user experience */
  .input_Type:focus {
    outline: none; /* Remove the default outline */
    border-bottom:1px solid #007bff; /* Change the border color on focus (blue in this example) */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a subtle box shadow on focus */
  }

  .Transaction_Row{
display: grid;
grid-template-columns: 1fr 1fr;
 
grid-template-areas: "Left right";
.Left{
    grid-area: Left;
    display: grid;

}
.right{
display: grid;
grid-area: right;

}
}


.runningbal{
    color: grey;
    font-size: 10px;
}

.transactions{
width: 100%;
    .card{

        margin: 10px auto;
        &.Outbound{
            border: 1px solid red;
        }
        
        &.Inbound{
            border: 1px solid green;
        }
        width: 80%;
       // background-color: #0a6887;
        border-radius: 15px;
max-height: 30px;
height: 30px;
padding: 15px;
display: grid;
grid-template-columns: 3fr 1fr;
grid-template-rows: 1fr 1fr;

grid-template-areas: 
"account amount",
"DateTime ."
;
.DateTime{
    font-size: 10px;

}
.reference{
    font-size: 10px;

}
    }
}