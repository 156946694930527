.CustomerList{
    width: 90%;
    margin: 0 auto;
}
.customerinformation{
    width: 90%;
    margin: 0 auto;
    .CryptoCard{
        background-color:$Primary;
        margin: 0 auto;
        border-radius: 15px;
       box-shadow: $shadow;
       font-family: 'Poppins', sans-serif;
    }
}