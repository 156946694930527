.tabs_Expenses{
    .MuiTabs-indicator {
        background-color: transparent; /* Change this to your desired color */
   //  text-align: left;

      }


      .tab {

        //  background-color: rgb(252, 252, 252);
          width: 95%;
          max-width: 200px;
          margin: 0 auto;
          border-radius: 5px;
          height: 50px;
          max-height: 75px;
          display: grid;
         // place-content: center;
          cursor: pointer;
      //  text-align: left;
          font-weight: 800;
    .MuiButtonBase-root{
      text-align: left;
    }
    
          z-index: 10;
    transition: 1s ease-in;
    border: .5px solid #0a688727;
          &:hover{
    
    
            background-color: #870a0a27;
          
            box-shadow: 0px 11px 38px -8px rgba(0,0,0,0.39);
            border: 1px solid #870a0a27;
          
            z-index: 100;
          transition: .25s ease-out;
          
          scale: 1.01;
          color: #870a0a;
          
          }
          &._selected {
          //  border: 1px solid red;
           // color: red;
            color: #870a0a;
    
            box-shadow: 0px 11px 38px -8px rgba(0,0,0,0.39);
            border: 1px solid #870a0a;
            background-color: #870a0a27;
    
            scale: 1.01;
            z-index: 100;
    
          }
        }
}