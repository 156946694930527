


.productArch{
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  margin: 5px 5px;
font-size: 11px;
padding: 15px;
}
.fileuploadmodal{
  grid-area: fileuploadmodal;


}
.iamgeupload{
  grid-area: iamgeupload;
  button{
    font-size: 9px;

  }

}

.title{


      background-color: #0a6887;

  
      padding: 5px;
      border-radius: 5px;
      text-align: center;
      color: rgb(255, 255, 255);
      font-weight: 600;

      top: 0;
      z-index: 0;
      padding: 10px;
      display: grid;
      grid-template-columns:20px 1fr 1fr 1fr 1fr;
      grid-row: 1fr 1fr;
      grid-template-areas: 
      "minimize-button titlename ArcCode OEM_Code iamgeupload"
      "fileuploadmodal fileuploadmodal fileuploadmodal fileuploadmodal fileuploadmodal"
      ;
      .minimize-button{
        grid-area: minimize-button;
        color: white;
        text-align: left;
        display: grid; 
        place-content: center;
      }
      .ArcCode{
        grid-area: ArcCode;
      }
      .OEM_Code{
        grid-area: OEM_Code;
      }
      .iamgeupload{
        button{
          color: rgb(255, 255, 255);

          font-size: 15px;
          padding: 0;
          margin: 0;
        }
      }
}

.products{
   display: grid;
}

.product{

      width: 90%;
      display: grid;
      align-items: center;
      
   
      margin: 0 auto;
      grid-template-rows:  1fr 1fr minmax(1fr,100px) auto 1fr  ;
      grid-template-columns:  1fr 1fr ;

      font-size: 10px;

   
    

     text-align: center;

  grid-template-areas: 
     "productname productname" 
     "suppliercode Acode"
     "Model brand_Name"
     "Unit_Price quantity"
     "Sensitive_Info Sensitive_Info"
     "addtocart addtocart"
     ;
 


     .suppliercode{
      text-align: left;
      grid-area: suppliercode;
     }
     .productname{
      grid-area: productname;

    display: flex;
    align-items: center;
    text-align: left;

     }
     .Acode{
      grid-area: Acode;
      text-align: right;
     }
   
     .Model{
      grid-area: Model;
     }
     .brand_Name{
      display: grid;
      justify-content: end;
      text-align: right;
      grid-area: brand_Name;
     }
     .quantity{
      grid-area: quantity; 
      display: grid;
      text-align: right;

     }
     .Unit_Price{
      text-align: left;
      grid-area: Unit_Price;
     }
     .Sensitive_Info{
      grid-area: Sensitive_Info;
     }
     .addtocart{
      grid-area: addtocart;
      justify-content: end;
  }
     .Flagitem{
      grid-area: Flagitem;
     }
     .BillingGroup{
      grid-area: BillingGroup;
     }
 }

//mediaquery for productrowonarch
@media screen and (min-width: 768px) {
  .title{
    font-size: 15px;
  }
    .product{
      font-size: 15px;
    display: grid;
    width: 100%;
    grid-template-rows:  1fr ;
    grid-template-columns: repeat(auto-fit, 200px); /* set a fixed width of 200px for each column */
    grid-template-columns: 2.5fr  1fr   1fr 1fr  1fr  ; /* set a fixed width of 200px for each column */
    grid-template-rows:  1fr;
    grid-template-areas: 
    " productname  brand_Name   quantity  Unit_Price   addtocart"
      ;
    .Unit_Price{
      text-align: right;
      grid-area: Unit_Price;
  //   background-color: rgba(182, 124, 15, 0.199);
    }
        .Acode{
         grid-area: Acode;
         text-align: center;
     //    background-color: rgba(15, 182, 85, 0.11);
        }
    }
}

.list {
    display: flex;
    flex-direction: column;
  }
  
  .item {
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 8px;
    background-color: #fff;
    cursor: grab;
  }
  
  .item:hover {
    background-color: #f4f4f4;
  }
  
  .item:active {
    cursor: grabbing;
    background-color: #eee;
  }
  .brand_container {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
 }
 .productbrandselectlist{
  width: 100%;
  margin: 0 auto;
  height: 150px;
  overflow-y: auto;
  overflow-x:hidden;

  .productbrandselect{
    margin: 15px 0 ;
    border: 1px solid $Primary;
    border-radius: 5px;
    padding:  5px 5px 5px 5px ;
    width: 80%;
    font-size: 12px;
 //   font-weight: 600;
    color: $Primary;
    cursor: pointer;
   text-align: left;
    list-style-type: none;
    &:hover{
      background-color: $Primary;
      color: white;
    }
  }
 }