.searchContainer {
    position: relative;
  }
  
  .searchInput {
    border: 2px solid #0a6887;
    padding-right: 30px; /* Adjust the padding to make room for the icon */


  }
  
  .clearIcon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: red;
  }
  

  .search_Primary{
  //  background-color: #0a6887;
    display: grid;
    grid-template-columns: 1fr .01fr .01fr  .01fr .01fr;
    border-radius: 10px;
    border: 2px solid #0a6887;
    input {
      border: none !important;
    }
    &.dark {
      background-color: #333333;
      color: #fff;
      }
    .search_input{
      border: none;

      &.dark {
        background-color: #333333;
      
          color: #ffffff;
    
        }
    }
    .caselock{
      font-weight: bold;
      font-size: 15px;
      cursor: pointer;
      display: grid;
      place-items: center;
      &.highlighted{
        color: #0a6887;
  
        }

        &.dark {
          background-color: #333333;
          color: #838383;
          &.highlighted{
            color: #ffffff;
      
            }
          }
    }
    .search_icon{
      display: grid;
          place-items: center;
    
          font-weight: bold;
          font-size: 15px;
          color:#0a6887;
    
          cursor: pointer;
    
       /*   &.highlighted{
          color: #0a6887;
    
          }
    */
          &.dark {
            background-color: #333333;
            color: #fff;
            }
    }
.clear_Search{
  display: grid;
      place-items: center;

      font-weight: bold;
      font-size: 15px;
      color:red;

      cursor: pointer;

      &.highlighted{
      color: #0a6887;

      }

      &.dark {
        background-color: #333333;
        color: #fff;
        }
}
    .Group_filter{
      display: grid;
      place-items: center;

      font-weight: bold;
      font-size: 15px;
      cursor: pointer;

      &.highlighted{
      color: #0a6887;

      &.dark {
        background-color: #333333;
        color: #838383;
        &.highlighted{
          color: #ffffff;
    
          }
        }

      }
    }

  }

  .Search_results{
    max-height: 50vh;
    z-index: 100000;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 5px;
    box-shadow:0px 3px 25px 0px rgba(0, 0, 0, 0.459);

background-color: #ffffff;
    position: absolute;
    max-width: inherit;
  /* top: 100%; Position below the parent */
   /*left: 0; Align with the left edge of the parent */
/*margin:  0 auto;
   Match the width of the parent */
 /* width: 80%;
   left: 50%; Align with the left edge of the parent */
  }