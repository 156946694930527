.orderview{
    width: 90%;
    margin: 0 auto;

    .CryptoCard{
        background-color:$Primary;
        border-radius: 15px;
       box-shadow: $shadow; 
        margin: 0 auto;
       font-family: 'Poppins', sans-serif;
    }
}