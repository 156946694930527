.dash{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    
    position: relative;

    width: 100%;
    height: inherit;

    overflow: hidden;

    &-nav{
        height: 100%;
        width: 70px;

        overflow: hidden auto;

        background-color: $main-grey;

        transition: .3s ease-in-out;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        @media screen and (max-width: $mobileMax) {
            width: 50px;
        }
        @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
        }
        @media screen and (min-width: $desktopMin) {
        }

        &.open{
            @media screen and (max-width: $mobileMax) {
                width: 70%;
            }
            @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
                width: 25%;
            }
            @media screen and (min-width: $desktopMin) {
                width: 250px;
            }

            .dash-nav-menu{
                a{
                    column-gap: 10px;

                    @media screen and (max-width: $mobileMax) {
                        column-gap: 0px;
                    }

                    svg{
                        //margin-right: 15px;

                        transition: fill .2s ease-in-out, margin-right .2s ease-in-out 0s;
                    }
                }
            }

            .dash-nav-toggle{
                column-gap: 10px;

                @media screen and (max-width: $mobileMax) {
                    column-gap: 0px;
                }

                svg{
                    //margin-right: 15px;

                    transition: fill .2s ease-in-out, transform .2s ease-in-out, margin-right .2s ease-in-out 0s;

                    transform: rotate(180deg);
                }
            }
        }

        a,
        button{
            display: block;
            white-space: nowrap;

            @media screen and (max-width: $mobileMax) {
                width: 70vw;
            }
            @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
                width: 25vw;
            }
            @media screen and (min-width: $desktopMin) {
                width: 250px;
            }
        }

        &-menu{
            width: 100%;

            @media screen and (max-width: $mobileMax) {
            }
            @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
            }
            @media screen and (min-width: $desktopMin) {
            }

            a{
                position: relative;
                display: grid;
                grid-template-columns: 1fr 4fr;
                align-items: center;
                column-gap: 25px;

                font-size: 18px;
                color: $Background_Light;

                font-weight: 500;

                line-height: 100%;
                box-sizing: border-box;
                
                transition: .2s ease-in-out;

                padding: 15px 20px;

                z-index: 2;

                @media screen and (max-width: $mobileMax) {
                    font-size: 16px;

                    column-gap: 10px;
                    padding: 15px 12.5px;
                }
                @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
                }
                @media screen and (min-width: $desktopMin) {
                }

                &:hover,
                &.active{
                    color: $Secondary;

                    svg{
                        fill: $Secondary;
                    }
                    
                    &::before {
                        left: 0;
                    }
                }
                &::before {
                    content: "";
                    position: absolute;
                    background-color: $Background_Light;
                    bottom: 0;
                    left: 100%;
                    right: 0;
                    top: 0;
                    z-index: -1;
                    transition: left .5s ease-out;
                }
                &.active{
                    &::before {
                        left: 0;
                    }
                }

                svg{
                    fill: $Background_Light;

                    transition: fill .2s ease-in-out, margin-right .2s ease-in-out .2s;

                    display: inline;
                    z-index: 1;
                    width: 28px;

                    @media screen and (max-width: $mobileMax) {
                        width: 24px;
                    }
                }
            }
        }

        &-toggle{
            display: grid !important;
            grid-template-columns: 1fr 4fr;
            align-items: center;
            column-gap: 25px;

            font-size: 18px;
            color: $Background_Light;

            font-weight: 500;

            line-height: 100%;
            box-sizing: border-box;
            
            transition: .2s ease-in-out;

            padding: 15px 25px;

            text-align: start;

            @media screen and (max-width: $mobileMax) {
                font-size: 16px;

                column-gap: 10px;
                padding: 15px 12.5px;
            }
            @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
            }
            @media screen and (min-width: $desktopMin) {
            }

            &:hover,
            &.active{
                color: $Secondary;

                svg{
                    fill: $Secondary;
                }
            }
            &.active{
                background-color: white;
            }

            svg{
                fill: $Background_Light;

                width: 28px;

                transition: fill .2s ease-in-out, transform .2s ease-in-out, margin-right .2s ease-in-out .2s;

                //margin-right: 30px;

                display: inline;

                @media screen and (max-width: $mobileMax) {
                    width: 24px;
                }
            }
        }
    }

    &-content{
        position: absolute;
        top: 0%;
        right: 0%;

        overflow-y: auto;

        height: 100%;
        width: calc(100% - 70px);

        transition: .3s ease-in-out;
        box-sizing: border-box;

        background-color: $Background_Light;

        @media screen and (max-width: $mobileMax) {
            padding: 15px;
            width: calc(100% - 50px);
        }
        @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
            padding: 25px 40px;
        }
        @media screen and (min-width: $desktopMin) {
            padding: 25px 80px;
        }

        &.open{
            @media screen and (max-width: $mobileMax) {
                right: calc(-70% + 50px);
            }
            @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
                right: calc(-25% + 70px);
            }
            @media screen and (min-width: $desktopMin) {
                right: calc(-250px + 70px);
            }
        }

        .dash-section{
            width: 100%;

            &-title{
                color: $main-grey;
                text-align: start;
                line-height: 100%;
                
                margin-bottom: 30px;

                @media screen and (min-width: $tabletMin) {
                    margin-bottom: 50px;
                }
            }

            &-nav{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                column-gap: 75px;
                row-gap: 50px;

                box-sizing: border-box;
                width: 100%;
                padding: 0 100px;

                @media screen and (max-width: $tabletMax) {
                    padding: 0px;
                    grid-template-columns: unset;
                    grid-template-rows: repeat(2, 1fr);
                    row-gap: 30px;
                }

                &-title{
                    padding: 0 75px;
                    margin-bottom: 50px;

                    @media screen and (max-width: $tabletMax) {
                        padding: 0px;
                        margin-bottom: 30px;
                    }
                }

                &-link{
                    box-sizing: border-box;
                    padding: 50px;
                    width: 100%;
                    //height: 75vh;

                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    background-color: $Background_Light;
                    color: $Primary;
                    border: 1px solid $Primary;
                    border-radius: 25px;
                    
                    box-shadow: $shadow;

                    font-size: 175%;

                    transition: .3s ease-in-out;

                    @media screen and (max-width: $tabletMax) {
                        font-size: 150%;

                        padding: 30px;
                    }

                    &:hover{
                        background-color: $Primary;
                        color: $Background_Light;

                        svg{
                            fill: $Background_Light;
                        }
                    }

                    svg{
                        transition: .3s ease-in-out;
                        
                        fill: $Primary;

                        height: auto;
                        margin-left: 50px;
                    }
                }

                &.home-nav{

                    @media screen and (min-width: $desktopMin) {
                        grid-template-columns: repeat(3, 1fr);
                    }
                }
            }

            &-search{
                width: 100%;

                margin-bottom: 25px;

                &-input{
                    position: relative;

                    input{
                        width: 100%;
    
                        &:focus{
                            ~ button{
                                border-left-color: $Primary;
                            }
                        }
                    }
                    button{
                        position: absolute;
                        top: 0%;
    
                        height: 100%;
                        padding: 10px 15px;
        
                        transition: .2s ease-in-out;
    
                        &:nth-of-type(1){
                            right: 0%;

                            border-radius: 0 15px 15px 0;
                            border: 1px solid $main-grey;
                            border-left-width: 1px;
    
                            background-color: $main-grey;
                            
                            &:focus,
                            &:hover{
                                //background-color: white;
        
                                svg{
                                    fill: $Secondary;
                                }
                            }
        
                            svg{
                                fill: $lighter-grey;
        
                                transition: .2s ease-in-out;
                            }
                        }
                        &:nth-of-type(2){
                            right: 54px;
                            
                            &:hover{
                                svg{
                                    fill: $danger;
                                }
                            }
        
                            svg{
                                fill: $main-grey;
        
                                transition: .2s ease-in-out;

                                height: 15px;
                            }
                        }
                    }
                }

                &-req{
                    color: $main-grey;
                    font-size: 90%;
                    font-weight: 400;
                    line-height: 100%;

                    display: block;
                    margin: 10px 0 0 10px;
                }
            }
            
            &-btns{
                @media screen and (max-width: $mobileMax) {
                    display: grid;
                    grid-template-columns: auto;
                    width: 100%;
                }
                @media screen and (max-width: $tabletMax) {
                    margin-bottom: 15px;
                }
                @media screen and (min-width: $desktopMin) {
                    display: flex;
                    flex-direction: row-reverse;
    
                    position: absolute;
                    right: 100px;
                }

                &-btn{
                    display: grid;
                    grid-template-columns: 15px auto;
                    column-gap: 15px;
                    align-items: center;
                
                    @media screen and (min-width: $tabletMin) {
                        grid-template-columns: 15px auto;
                    }
                    @media screen and (min-width: $desktopMin) {
                        &:not(:last-of-type){
                            margin-left: 10px;
                        }
                    }
    
                    border: 1px solid $Primary;
                    background-color: $Primary;
                    border-radius: 15px;
    
                    text-transform: capitalize;
                    font-weight: 500;
                    line-height: 100%;
    
                    //margin: 25px 0;
                    padding: 15px;
                    width: max-content;
    
                    transition: .2s ease-in-out;
                    
                    color: white;
                    
                    @media screen and (max-width: $mobileMax) {
                        padding: 12.5px;
                        width: auto;
                    }
    
                    svg{
                        height: 15px;
                    
                        fill: white;
                    
                        @media screen and (min-width: $tabletMin) {
                            //height: 17.5px;
                        }
                    }
    
                    &:hover{
                        opacity: .9;
                    }
                }
            }

            &-sort,
            &-filter{
                z-index: 999;
                position: fixed;
                top: 0%;
                right: 0%;
                transform: translateX(100%);
                width: 25%;
                height: 100%;

                overflow: hidden;
                box-sizing: border-box;

                background-color: white;
                box-shadow: $shadow; 
                border-radius: 0px;

                padding: 30px;

                transition: transform .3s ease-in-out;

                @media screen and (max-width: $mobileMax) {
                    width: 100%;
                }
                @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
                    width: 50%;
                }

                &.open{
                    transform: translateX(0%);
                }

                &-top{
                    width: 100%;
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: space-between;
                    align-items: center;

                    margin-bottom: 30px;
                    padding-bottom: 15px;

                    border-bottom: 1px solid $unfocused-grey;

                    button{
                        padding: 0;
    
                        svg{
                            fill: $main-grey;
                            transform: rotate(180deg);
                            height: 17.5px;
                        }
                    }

                    h3{
                        display: inline;
                    }
                }

                p{
                    font-weight: 500;
                    line-height: 100%;
                    font-size: 16px;

                    margin-bottom: 5px;
                }

                &-filters{
                    &-single{
                        &:not(:first-of-type){
                            margin-top: 15px;
                        }
                        
                        &-items{
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                        }

                        &-item{
                            display: inline;

                            input{
                                margin-right: 5px;
                            }
                            label{
                                margin-right: 15px;
                            }
                        }
                    }
                }

                &-items{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                }
            }

            &-view{
                overflow: auto hidden;    
                display: flex;
                flex-direction: column;
                
                box-sizing: border-box;
                padding: 0 20px;
                
                @media screen and (max-width: $tabletMax) {
                    padding: 0;
                }
                @media screen and (min-width: $desktopMin) {
                    padding: 5px 20px 0;
                }

                &-btns{
                    width: max-content;
                    float: left;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;

                    border-radius: 15px 15px 0 0;
                    background-color: $main-grey;

                    &.empty{
                        border-radius: 15px;
                    }

                    button{
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        padding: 15px 20px;
    
                        svg{
                            height: 24px;
                            width: auto;
                            fill: $Background_Light;
                        }
                                
                        @media screen and (max-width: $mobileMax) {
                            padding: 15px;

                            svg{
                                height: 20px;
                            }
                        }
                    }

                    &-sortbtn{
                        svg{
                            stroke: $Background_Light;
                            stroke-width: .5px;
                        }
                    }
                    &-orderbtn{
                        &.desc{
                            svg{
                                transform: rotate(180deg);
                            }
                        }
                    }
                }

                table{
                    width: 100%;
                    box-shadow: $shadow; 
        
                    border-collapse: separate !important;
                    border-spacing: 0;
                    border-radius: 0 15px 15px 15px;
        
                    overflow: hidden;
        
                    thead{
                        color: $lighter-grey;
        
                        background-color: $main-grey;
                        width: 100%;
        
                        th{
                            position: sticky;
                            top: 0;
                            padding: 20px 30px;
                                
                            @media screen and (max-width: $mobileMax) {
                                padding: 20px 10px;
                            }
                        }
                    }
        
                    tbody{
                        width: 100%;
    
                        tr{
                            background-color: white;
                            opacity: .9;
    
                            transition: .1s ease-in-out;
        
                            &:nth-child(even){
                                //background-color: $lightest-grey;
                            }
    
                            &:hover{
                                opacity: 1;
                            }
        
                            td{
                                color: $main-grey;
                                padding: 20px 30px;
                                
                                @media screen and (max-width: $mobileMax) {
                                    padding: 10px;
                                }
    
                                a{
                                    display: block;
    
                                    text-transform: uppercase;
                                    font-weight: 600;
                                    line-height: 100%;
    
                                    padding: 10px 15px;
                                    width: max-content;
    
                                    transition: .2s ease-in-out;
                                    
                                    color: $Primary;
    
                                    &:hover{
                                        color: $Secondary;
                                    }
                                }
                            }
                        }
                    }

                    &.outbound-table{
                        tbody{
                            width: 100%;
        
                            tr{
                                td{
                                    &:last-of-type{
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: center;
                                        align-items: center;

                                        input[type="button"]{
                                            display: block;
            
                                            font-weight: 500;
                                            line-height: 100%;
            
                                            padding: 15px 20px;
                                            width: max-content;
                                            height: 100%;
                                            
                                            transition: .2s ease-in-out;

                                            &:nth-of-type(1){
                                                border: 1px solid $Primary;
                                                background-color: $Background_Light;
                                                color: $Primary;

                                                margin-right: 10px;
                                            }
                                            &:nth-of-type(2){
                                                border: 1px solid $danger;
                                                background-color: $bg-red;
                                                color: $danger;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    
                &-loadmore{
                    width: 100%;
                    padding: 12.5px 15px;
                    margin-top: 20px;
    
                    border-radius: 15px;
    
                    border: 1px solid $Primary;
                    background-color: $Primary;
                    color: white;
                    font-size: 18px;
                    font-weight: 500;
                    text-transform: capitalize;
    
                    transition: .2s ease-in-out;
    
                    align-self: center;
    
                    &:hover{
                        opacity: .9;
                    }
                }

                &-msg{
                    display: block;
                    box-sizing: border-box;

                    width: 100%;
                    padding: 10px 20px;
                    margin-top: 20px;
                    
                    color: $unfocused-grey;
                    font-weight: 500;
                    text-align: center;
    
                    transition: .2s ease-in-out;
    
                    align-self: center;
                }
            }

            &-form{
                position: absolute;
                bottom: 0%;
                left: 0%;
                z-index: 2;
                width: 100%;
                height: 100%;

                background-color: $Background_Light;

                box-sizing: border-box;

                &-top{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;

                    button{
                        padding: 30px;

                        @media screen and (max-width: $mobileMax) {
                            padding: 20px;
                        }
    
                        &:hover{
                            svg{
                                opacity: .9;
                            }
                        }
    
                        svg{
                            fill: $main-grey;
                            transform: rotate(180deg);
    
                            transition: .2s ease-in-out;
                        }
                    }

                    h2{
                        display: inline;
                    }
                }

                &-content{
                    box-sizing: border-box;
                    padding: 30px 75px;

                    @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
                        padding: 30px 50px;
                    }
                    @media screen and (max-width: $mobileMax) {
                        padding: 30px;
                    }

                    h3{
                        margin-bottom: 25px;
                        line-height: 100%;
                    }

                    .labeled-input{
                        margin-bottom: 30px;

                        @media screen and (max-width: $mobileMax) {
                            margin-bottom: 20px;
                        }
                    }

                    /*form{
                        border-radius: 20px;
                        background-color: white;
                        box-shadow: $shadow;
                        
                        padding: 50px;
                        
                        @media screen and (max-width: $mobileMax) {
                            padding: 30px;
                        }
                    }*/
                }

                &-btns{
                    box-sizing: border-box;
                    width: 100%;
                    padding: 15px 75px 100px;

                    @media screen and (max-width: $mobileMax) {
                        padding: 10px 30px 40px;
                    }

                    button,
                    input[type=submit],
                    input[type=button]{
                        font-size: 95%;
                        padding: 15px 20px;

                        @media screen and (max-width: $mobileMax) {
                            width: 100%;
                        }
                    }
                }

                &-danger{
                    display: grid;
                    row-gap: 30px;

                    box-sizing: border-box;
                    width: 75%;
                    padding: 25px 40px;
                    margin: 75px auto 100px;

                    border: 1px solid $danger;
                    border-radius: 20px;

                    @media screen and (max-width: $tabletMax) {
                        width: 100%;
                        padding: 20px 20px;
                        margin: 50px auto;
                    }

                    button,
                    input[type=submit],
                    input[type=button]{
                        font-size: 95%;
                        padding: 15px 20px;
                        height: max-content;

                        margin: auto;

                        @media screen and (max-width: $mobileMax) {
                            width: 100%;
                        }
                    }

                    &-section{
                        display: grid;
                        grid-template-columns: 2.5fr 1fr;
                        column-gap: 30px;

                        input{
                            width: 100%;  
                        }

                        @media screen and (max-width: $tabletMax) {
                            display: flex;
                            flex-direction: column;

                            div{
                                margin-bottom: 10px;
                            }
                        }
                    }
                }

                .inline-btns{
                    justify-content: flex-end;
    
                    input{
                        @media screen and (min-width: $desktopMin) {
                            width: max-content !important;
                        }
                    }
                }
                &-addbtn,
                &-editbtn,
                &-greenbtn{
                   background-color: $Primary;
                   color: white;
                   border: 1px solid $Primary !important;
                }
                &-cancelbtn,
                &-redbtn{
                    background-color: transparent;
                    color: $danger;
                    border: 1px solid $danger !important;
    
                    @media screen and (min-width: $tabletMin) {
                        margin-right: 15px;
                    }
                }
                &-disablebtn,
                &-deletebtn{
                    background-color: transparent;
                    color: $danger;
                    border: 1px solid $danger !important;
    
                    @media screen and (min-width: $tabletMin) {
                        margin-right: 15px;
                    }

                    &:hover{
                        color: $Background_Light;
                        background-color: $danger;
                    }
                }
                &-enablebtn{
                    background-color: transparent;
                    color: $Primary;
                    border: 1px solid $Primary !important;
    
                    @media screen and (min-width: $tabletMin) {
                        margin-right: 15px;
                    }

                    &:hover{
                        color: $Background_Light;
                        background-color: $Primary;
                    }
                }
            }
        }
    }

    &-details-section{
        border-radius: 20px;
        background-color: white;
        box-shadow: $shadow;
        
        padding: 50px;
        margin-bottom: 35px;

        @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) {
            padding: 35px;
        }
        @media screen and (max-width: $mobileMax) {
            padding: 30px;
        }
    }
}