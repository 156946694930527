
  .CartPage{
    font-size: 13px;
    display: grid;
    width: 100vw;
    max-width: 1920px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: minmax(1fr,250px) 1fr 1fr minmax(1fr,250px);
    grid-template-areas: 
    "customer Sales_rep_selector Cartemptymessage totalItemsincart"
    "CartItems CartItems CartItems CartItems"
    "CartItems CartItems CartItems CartItems"
    "cartbottom cartbottom cartbottom cartbottom"
    ;
    .totalItemsincart{

      grid-area: totalItemsincart;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
       place-items: center;
       display: grid;
    }
    .billing_group{
      border: 1px solid $Primary; 
       border-radius: 10px;
       margin: 15px auto;
       width: 95%;
       padding: 15px;
    }
    .CartItems {
      grid-area: CartItems;
//background-color: rgb(236, 255, 127);

        display: grid;
       // box-shadow: $shadow;
        border-radius: 10px;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(auto);
       //   border: 1px solid $Primary;
        margin: 15px auto;
        width: 95%;
      //  background-color: aquamarine;
        
   
       }

       .Cartemptymessage{
        grid-area: Cartemptymessage;
        font-size: 20px;
        font-weight: 600;
        text-align: center;       
        place-items: center;
        display: grid;
       }
       
  }
.Cart_row_title_C{
 // box-shadow: 0px 11px 38px -8px rgba(0,0,0,0.39);

  display: grid;
  background-color: #0a6887;
                    border-radius: 5px;
                    color: white;
                    scale: 1;
            transition: none ;

  grid-template-columns:1fr .1fr;
 grid-row: 1fr ;
  //  grid-row: 1fr 1fr 1fr 1fr;
    grid-template-areas: 
    "Titles_Cart_Line_Item  Titles_Cart_Line_Remove"
    ;

    .Titles_Cart_Line_Item{
      //  background-color: rgb(92, 54, 4);
        grid-area: Titles_Cart_Line_Item;
  
      }
      .Titles_Cart_Line_Remove{
        //  background-color: rgb(92, 54, 4);
          grid-area: Titles_Cart_Line_Remove;

         // font-weight: 600;
          color: red;
          color: white;
          margin: 0 auto;
      }
}
  .CartRow{

    display: grid;
    margin: 10px 0;
    border-radius: 5px;


    font-weight: lighter;
    font-size: 14px;
                  margin: 0 auto;
                    width: 100%;
                    border-radius: 5px;
                   // box-shadow:0px 3px 25px 0px rgba(0, 0, 0, 0.233);
                   padding: 5px 0;
    
                   z-index: 10;
    transition: 1s ease-in;
    border: .5px solid #0a688727;


    &:hover{
      background-color: #0b54946c;

      background-color: #0b549425;
      box-shadow:0px 3px 25px 0px rgba(0, 0, 0, 0.233);
  
      box-shadow: 0px 11px 38px -8px rgba(0,0,0,0.39);
      border: 1px solid #0a688727;
  
      z-index: 100;
  transition: .25s ease-out;
  
  scale: 1.01;

  &.Title{
      background-color: #0a6887;
      border-radius: 5px;
      color: white;
      scale: 1;
transition: none ;

}
    }


 grid-template-columns:1fr .1fr;
 grid-row: 1fr ;
  //  grid-row: 1fr 1fr 1fr 1fr;
    grid-template-areas: 
    "cartprodcut  Remove"
    ;
    .cartprodcut{
    //  background-color: rgb(92, 54, 4);
      grid-area: cartprodcut;
      font-weight: lighter;

    }
    .Discount{
    //  background-color: antiquewhite;

      grid-area: Discount;
    }
    .Quantity{
   //   background-color: antiquewhite;
      grid-area: Quantity;
    }
    .Subtotal{
//background-color: antiquewhite;
      grid-area: Subtotal;
    }
    .Remove{
      grid-area: Remove;
      font-weight: 600;
      color: red;
      margin: 0 auto;
    }
  }

  .cartbottom{
    width: 90%;
    margin: 0 auto;
    grid-area: cartbottom;
//background-color: rgba(127, 255, 212, 0.5);
    .carttotal{
      display: grid;
      font-weight: 600;
      font-size: 20px;
      text-align: right;
      grid-area: carttotal ;
      margin: auto;
    }
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 
    "clearcart placeorder carttotal";
    .placeorder{
      grid-area: placeorder;
    }
    .clearcart{
      grid-area: clearcart;
    }
  }


  .customer{
    grid-area: customer;
    max-width: 400px;
    border-radius: 15px;
    margin: 15px auto;
    font-weight: 600;
    font-size: 18px;
        border: 2px solid $Primary;
        width: 80%;
    padding: 10px;
    background-color: $Primary-Blue-Background;
    text-align: left;
    display: grid;
    place-content: center;
    .CustomerSelectlist{
      text-align: left;
      width: 100%;
      margin: 0 auto;
      height: 150px;
      overflow-y: auto;
      overflow-x:hidden;
      .CustomerSelect{
      margin: 15px 0 ;
        border: 1px solid $Primary;
        border-radius: 5px;
        padding:  5px 5px 5px 5px ;
        width: 100%;
        font-size: 15px;
        font-weight: 600;
        color: $Primary;
        cursor: pointer;
       
        list-style-type: none;
        &:hover{
          background-color: $Primary;
          color: white;
        }

       
      }
    }
  }

