.image-slider {
    &.empty {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
  
      p {
        font-size: 2rem;
        color: gray;
      }
    }
  
    .image-wrapper {
      position: relative;
      grid-area: image-wrapper;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
  
      &.zoomed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        cursor: zoom-out;
      }
    }
  
    .nav {
      grid-area: nav;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
  
      .nav-button {
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        border: none;
        border-radius: 50%;
        font-size: 2rem;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
  
        &:hover {
          background-color: rgba(0, 0, 0, 0.8);
          transform: scale(1.2);
        }
      }
  
      .prev {
        transform: rotateY(180deg);
      }
    }
  
    .image-selector {
      grid-area: image-selector;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      padding: 1rem;
  
      .image-selector-button {
        background-color: rgba(0, 0, 0, 0.2);
        border: none;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
  
        &:hover,
        &.active {
          background-color: rgba(0, 0, 0, 0.5);
        }
  
        &.active {
          transform: scale(1.2);
        }
      }
    }
  
    .report-button {
      grid-area: report-button;
      background-color: white;
      border: 2px solid black;
      color: black;
      font-size: 2rem;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      position: absolute;
      bottom: 20px;
      right: 20px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
  
      &:hover {
        background-color: black;
        color: white;
        transform: scale(1.2);
      }
    }
  }
  .image-slider-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
  
    .image-slider {
      display: grid;
      grid-template-areas:
        "image-wrapper"
        "nav"
        "image-selector"
        "report-button";
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto auto auto;
      width: 80%;
      height: 80%;
      background-color: white;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  
      @media (min-width: 768px) {
        grid-template-areas:
          "image-wrapper nav"
          "image-selector image-selector"
          "report-button report-button";
        grid-template-columns: 1fr 50px;
        grid-template-rows: 1fr auto auto;
        width: 90%;
        height: 90%;
      }
  
      &.empty {
        grid-template-areas:
          "empty-message empty-message"
          "nav nav"
          "image-selector image-selector"
          "report-button report-button";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr auto auto auto;
  
        .empty-message {
          grid-area: empty-message;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
  
          p {
            font-size: 2rem;
            color: gray;
          }
        }
      }
    }
  }
    